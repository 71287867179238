export default {
  BUTTON_PRIMARY_CLASS: 'button btn--primary',
  BUTTON_SECONDARY_CLASS: 'button btn--secondary',

  LOAD_MORE_TEXT: '<span>{window.themeSettings.locales.load_more}</span>',
  SORT_BY: '{window.themeSettings.locales.sort_select}',
  BROWSE_ALL: '{window.themeSettings.locales.browse_all} ({{totalHits}})',

  FILTERS_TEXT: '{window.themeSettings.locales.mobile_filter_button}',
  PRICE_FACET_GO_TEXT: '{window.themeSettings.locales.price_filter_button}',
  CURRENT_SEARCH: '{window.themeSettings.locales.current_search}',
  START_OVER: '{window.themeSettings.locales.start_over}',
  TOTAL_HITS: '{window.themeSettings.locales.total_results}',
  FACET_DIALOG_TITLE: '{window.themeSettings.locales.mobile_filter_dialog_title}',
  FACET_DIALOG_RESULTS: '{window.themeSettings.locales.mobile_filter_dialog_results}',
  FACET_DIALOG_CLEAR: '{window.themeSettings.locales.mobile_filter_dialog_clear}',
  FACET_DIALOG_DONE: '{window.themeSettings.locales.mobile_filter_dialog_done}',

  SELECT_YOUR_VEHICLE: '{window.themeSettings.locales.ymm.select_block}',
  CHANGE_VEHICLE: '{window.themeSettings.locales.ymm.change}',
  CHANGE_TEXT: '{window.themeSettings.locales.ymm.change}',
  DISCARD_VEHICLE: '{window.themeSettings.locales.ymm.clear}',
  GO_TEXT: '{window.themeSettings.locales.ymm.go}',
  CLEAR_TEXT: '<rt-virtual template="icons/reset" />',

  GARAGE_BUTTON_CLASS: 'shopcart-dropdown block-cart-link',
  GARAGE_DROPDOWN_CLASS: 'widget woocommerce widget_shopping_cart',
  GARAGE_CLASS: 'navUser-action custom',
  GARAGE_ICON: '<div class="garage-icon cm_icon_garage__background icon"></div>',
  GARAGE_SIZE: '<span class="garage-size countPill" key="garage-size">{{size}}</span>',

  VERIFY_FITMENT_TITLE: '{window.themeSettings.locales.ymm.verify_fitment}',
  // VERIFY_FITMENT_FITS: '{window.themeSettings.locales.ymm.fits}',
  // VERIFY_FITMENT_NOT_FIT: '{window.themeSettings.locales.ymm.not_fits}',
  VERIFY_FITMENT_SPECIFY:
    '{window.themeSettings.locales.ymm.specify} {{fieldsToSpecify.join(", ")}} for your VERIFY_FITMENT_VEHICLE',
  FITMENT_UNIVERSAL_TITLE: '{window.themeSettings.locales.ymm.universal_title}',
  FITMENT_UNIVERSAL_TEXT: '{window.themeSettings.locales.ymm.universal_text}',
  FITMENT_UNKNOWN_TITLE: '{window.themeSettings.locales.ymm.unknown_title}',
  FITMENT_UNKNOWN_TEXT: '{window.themeSettings.locales.ymm.unknown_text}',

  FITMENT_TABLE_VEHICLE_SPECIFIC: `{window.themeSettings.locales.ymm.fitment_table_title || 'Fitment'}`,

  SELECT_TITLE: `{window.themeSettings.locales.ymm[this.title.toLowerCase().replace(' ', '_')] || this.title}`,
};
